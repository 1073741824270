import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const About = ( {data, location} ) => {
  const post = data.allMarkdownRemark.edges
  return (
    <Layout link={location}>
      <Seo title="Shariful Nibir" />
      {post.map(({ node }) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: node.html }} key={node.id} />
        )
      })}
    </Layout>
  )
}

export default About

export const aboutQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/about/" } }
      ) {
      edges {
        node {
          excerpt
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`
